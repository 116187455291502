export const marginZeroStyles = {
  marginZero: {
    margin: 0,
    paddingBottom: '0.5rem',
  },
};

export const DAYS_MODE_DOW = 'dow';

export const DAYS_MODE_DOM = 'dom';

export const DAYS_MODE_ED = 'ed';

export const WEEK_DAYS = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

export const MONTH_DAYS = [
  ...[...Array(31).keys()].map(i => ({
    label: i + 1,
    value: i + 1,
  })),
  {
    label: 'Last',
    value: -1,
  },
];

export const communicationCategoryGroupChoices = [
  { id: 'collection', name: 'Collection' },
  { id: 'marketing', name: 'Marketing' },
  { id: 'support', name: 'Support' },
  { id: 'underwriting', name: 'Underwriting' },
  { id: 'compliance', name: 'Compliance' },
];

export const applicationStatusChoices = [
  { id: 'pending', name: 'Pending' },
  { id: 'processing', name: 'Processing' },
  { id: 'approved', name: 'Approved' },
  { id: 'rejected', name: 'Rejected' },
  { id: 'confirmed', name: 'Confirmed' },
  { id: 'cancelled', name: 'Cancelled' },
  { id: 'error', name: 'Error' },
  { id: 'verification_control', name: 'Verification control' },
];

export const loanStateList = [
  { name: 'Pending', key: 'pending' },
  { name: 'Active', key: 'active' },
  { name: 'Closed', key: 'closed' },
  { name: 'Defaulted', key: 'defaulted' },
  { name: 'Cancelled', key: 'cancelled' },
  { name: 'Inactive', key: 'inactive' },
  { name: 'Cep fail', key: 'cep_fail' },
  { name: 'Transfer fail', key: 'transfer_fail' },
  { name: 'Sold', key: 'sold' },
];

export const dangerPrincipalValue = 5000;
export const warningPrincipalValue = 3000;

export const PHONE_COUNTRY_CODE = '+51';

export const CURRENCY = 'PEN';
