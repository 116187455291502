import React from 'react';
import PropTypes from 'prop-types';
import {
  Edit,
  TextInput,
  BooleanInput,
  SaveButton,
  DeleteButton,
  FormWithRedirect,
  required,
  maxLength,
  usePermissions,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

const FormWrapper = ({ save, ...props }) => {
  const { permissions = [] } = usePermissions();

  const isEditable = permissions.includes('CAN_SHORT_LINK_EDIT');

  return (
    <FormWithRedirect
      save={(data, ...rest) => save(...[{ ...data, _params: { method: 'PATCH' } }, ...rest])}
      {...props}
      render={formProps => (
        <form>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={12} md={6}>
              <Paper>
                <Box p={2}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" gutterBottom={false}>
                      Short link
                    </Typography>
                    <BooleanInput disabled={!isEditable} source="is_enabled" helperText={false} label={'Enabled'} />
                  </Grid>
                  <Divider />
                </Box>
                <Box p={2}>
                  <Grid container spacing={2} direction="column">
                    <Grid item xs={12} sm={12}>
                      <TextInput
                        disabled={!isEditable}
                        source="slug"
                        validate={[required(), maxLength(255)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInput
                        disabled={!isEditable}
                        source="link"
                        validate={[required(), maxLength(255)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Box display="flex" justifyContent="space-between">
                        <SaveButton
                          disabled={!isEditable}
                          label="Save"
                          redirect="list"
                          saving={formProps.saving}
                          handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                        />
                        <DeleteButton
                          undoable={false}
                          disabled={!isEditable}
                          basePath={formProps.basePath}
                          record={formProps.record}
                          resource={formProps.resource}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const ShortLinkEdit = props => (
  <Edit component="div" mutationMode="pessimistic" {...props}>
    <FormWrapper />
  </Edit>
);

export default ShortLinkEdit;
