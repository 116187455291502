import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  ChipField,
  Datagrid,
  Filter,
  FunctionField,
  List,
  NumberField,
  NumberInput,
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
  usePermissions,
  SelectArrayInput,
} from 'react-admin';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { formatDatetime, formatCurrency } from '../../../utils/formatter';
import Pagination from '../../Pagination';
import DateFilterInput from '../../filter/DateFilterInput';
import AsyncSelectInput from '../../input/AsyncSelectInput';
import ExclusivePairInput from '../../input/ExclusivePairInput';
import ExclusiveFourOptInput from '../../input/ExclusiveFourOptInput';
import { getUserId } from '../../../utils/authentication';
import { CURRENCY, marginZeroStyles } from '../../../constants';
import { getPTPStatusColor } from '../../../utils/getPTPStatusColor';
import ComparePrincipalsBox from './components/ComparePrincipalsBox';
import { PTP_STATUS_CHOICES } from '../../../utils/dictionary';

const useStyles = makeStyles(() => ({
  minWidth140: {
    minWidth: 140,
  },
  ...marginZeroStyles,
}));

const ListFilter = props => {
  const classes = useStyles();
  const { permissions = [] } = usePermissions();

  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <SelectArrayInput
        label="PTP status"
        source="status__"
        choices={PTP_STATUS_CHOICES}
        className={classes.minWidth140}
        alwaysOn
      />
      {permissions.includes('CAN_VIEW_ALL_COLLECTION_LOANS') && (
        <AsyncSelectInput
          alwaysOn
          resettable
          label="Collector Id"
          source="admin->id"
          query={{
            resource:
              'admins?role_permission=CAN_BE_COLLECTION_SPECIALIST&is_blocked=false&items_per_page=200&order[username]=asc',
            payload: { pagination: {}, sort: {}, filter: {} },
          }}
          mapper={({ id, username }) => ({ value: id, name: username })}
        />
      )}
      <ExclusiveFourOptInput
        label="PTP"
        firstOption={{ source: 'is_ptp_today', label: 'exp. today' }}
        secondOption={{ source: 'is_ptp_tomorrow', label: 'exp. tomorrow' }}
        thirdOption={{ source: 'is_ptp_broken_1', label: 'broken 1' }}
        fourthOption={{ source: 'is_ptp_broken_3', label: 'broken 3' }}
        alwaysOn
        resettable
      />
      <ExclusivePairInput
        label="Coverage"
        firstOption={{ source: 'not_covered', label: 'not covered' }}
        secondOption={{ source: 'not_covered_today', label: 'not covered today' }}
        alwaysOn
        resettable
      />
      <NumberInput label="PTP Id" source="id" />
      <NumberInput label="Loan Id" source="loan->id" />
      <NumberInput label="User Id" source="user->id" />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
      <DateFilterInput label="Promised before" source="promise_date|before" before />
      <DateFilterInput label="Promised after" source="promise_date|after" after />
      <DateFilterInput label="Completed before" source="completed_at|before" before />
      <DateFilterInput label="Completed after" source="completed_at|after" after />
      <DateFilterInput label="Failed before" source="failed_at|before" before />
      <DateFilterInput label="Failed after" source="failed_at|after" after />
      <NumberInput label="Max promise amount" source="promise_amount|lte" />
      <NumberInput label="Min promise amount" source="promise_amount|gte" />
      <NumberInput label="Max paid amount" source="paid_amount|lte" />
      <NumberInput label="Min paid amount" source="paid_amount|gte" />
    </Filter>
  );
};

ListFilter.propTypes = {
  collectiongroups: PropTypes.array,
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const CollectionPTPList = props => {
  const userId = getUserId();
  const { permissions = [] } = usePermissions();

  const loanRowStyle = record => ({
    backgroundColor: getPTPStatusColor(record),
  });

  if (permissions.length === 0) return null;

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'promise_date', order: 'DESC' }}
      filters={<ListFilter />}
      filterDefaultValues={permissions.includes('CAN_VIEW_ALL_COLLECTION_LOANS') ? null : { 'admin.id': userId }}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowStyle={loanRowStyle} rowClick={(_id, _path, { loan }) => `/loans/${loan}/show`}>
        <FunctionField
          source="promise_date"
          render={({ promise_date }) => formatDatetime(promise_date)}
          label="PTP date"
        />
        <FunctionField
          sortable={false}
          source="created_at"
          render={({ created_at }) => formatDatetime(created_at)}
          label="PTP Created"
        />
        <FunctionField
          sortable={false}
          label="Loan"
          render={({ loan }) => (
            <Link onClick={e => e.stopPropagation()} href={`#loans/${loan}/show`} target="_blank" rel="noreferrer">
              {`#${loan}`}
            </Link>
          )}
        />
        <FunctionField
          sortable={false}
          label="DPD"
          render={record => <Chip label={`${record.collection_group ?? 'Er'} (${record.dpd})`} />}
        />
        <FunctionField
          label="Client"
          render={({ user, user_name }) => (
            <Link onClick={e => e.stopPropagation()} href={`#users/${user}`} target="_blank" rel="noreferrer">
              {user_name}
            </Link>
          )}
        />
        <FunctionField label="Collector" render={({ collector_name }) => collector_name} />
        <FunctionField
          sortable={false}
          source="loan_principal"
          label="Principal"
          render={({ loan_principal }) => <ComparePrincipalsBox principalValue={loan_principal} />}
        />
        <NumberField
          sortable={false}
          source="loan_debt_amount"
          label="Debt amount"
          options={{ style: 'currency', currency: CURRENCY, currencyDisplay: 'narrowSymbol' }}
        />
        <NumberField
          source="promise_amount"
          label="PTP amount"
          options={{ style: 'currency', currency: CURRENCY, currencyDisplay: 'narrowSymbol' }}
        />
        <NumberField sortable={false} source="count_attempts" label="Number of attempts (in process)" />
        <FunctionField
          sortable={false}
          source="date_my_last_call"
          label="Last call date of PTP owner (from PTP created)"
          render={({ date_my_last_call }) => formatDatetime(date_my_last_call)}
        />
        <NumberField
          sortable={false}
          source="count_contacts"
          label="Number of contacts with 'Follow up on PTP' (in process)"
        />
        <FunctionField
          sortable={false}
          source="last_follow_date"
          label="Last 'Follow up on PTP' date (from PTP created)"
          render={({ last_follow_date }) => formatDatetime(last_follow_date)}
        />
        <FunctionField
          sortable={false}
          source="my_last_follow_date"
          label="Last 'Follow up on PTP' of PTP owner (from PTP created)"
          render={({ my_last_follow_date }) => formatDatetime(my_last_follow_date)}
        />
        <FunctionField
          sortable={false}
          source="last_payment_date"
          render={({ last_payment_date }) => formatDatetime(last_payment_date)}
          label="Last payment date"
        />
        <FunctionField
          source="paid_amount"
          label="Paid amount"
          render={({ count_payments, paid_amount }) =>
            paid_amount ? `${formatCurrency(paid_amount, true)} (${count_payments})` : '---'
          }
        />
        <ChipField sortable={false} size="small" source="status" label="PTP status" />
      </Datagrid>
    </List>
  );
};

export default CollectionPTPList;
