import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ChipField,
  FunctionField,
  Filter,
  TextInput,
  NumberInput,
} from 'react-admin';
import Link from '@material-ui/core/Link';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';

import ListFilterHOC from '../../hoc/ListFilterHOC';
import MarkAllButton from './components/MarkAllButton';
import ActivateButton from './components/ActivateButton';
import ListActions from './components/ListActions';
import Pagination from '../../Pagination';
import DateFilterInput from '../../filter/DateFilterInput';
import QuickFilterInput from '../../filter/QuickFilterInput';
import { CURRENCY, marginZeroStyles } from '../../../constants';
import { formatDatetime } from '../../../utils/formatter';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilterExtensionRequest = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <NumberInput label="User Id" source="user->id" />
      <TextInput label="User name" source="search_by_name" />
      <TextInput label="Document number" source="user->userDocuments->number" />
      <NumberInput label="Min principal" source="principal|gte" />
      <NumberInput label="Max principal" source="principal|lte" />
      <NumberInput label="Min tenor" source="tenor|gte" />
      <NumberInput label="Max tenor" source="tenor|lte" />
      <DateFilterInput label="Matured before" source="matured_at|before" before />
      <DateFilterInput label="Matured after" source="matured_at|after" after />
      <DateFilterInput label="Extension requested before" source="extension_requested_at|before" before wide />
      <DateFilterInput label="Extension requested after" source="extension_requested_at|after" after wide />
      <QuickFilterInput label="Extension min payment = 0" source="income_amount_for_extension|lte" defaultValue={'0'} />
      <QuickFilterInput label="Extension min payment > 0" source="income_amount_for_extension|gt" defaultValue={'0'} />
    </Filter>
  );
};

const BulkActionButtonsExtension = props => (
  <ButtonGroup {...props}>
    <MarkAllButton {...props} />
    <ActivateButton {...props} />
  </ButtonGroup>
);

const ListExtensionRequest = props => {
  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={<BulkActionButtonsExtension />}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilterExtensionRequest />}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <FunctionField
          label="Name"
          render={({ user_id, user_first_name = '', user_last_name = '' }) => (
            <Link onClick={e => e.stopPropagation()} href={'#users/' + user_id}>
              {`${user_first_name} ${user_last_name} #${user_id}`}
            </Link>
          )}
        />
        ,
        <NumberField source="principal" options={{ style: 'currency', currency: CURRENCY }} />
        <NumberField source="tenor" />
        <ChipField size="small" source="state" sortable={false} />
        <FunctionField
          render={({ extension_requested_at }) => formatDatetime(extension_requested_at)}
          label="Extension requested at"
        />
        <FunctionField render={({ matured_at }) => formatDatetime(matured_at)} source="matured_at" label="Matured at" />
        <NumberField source="income_amount_for_extension" label="Extension min payment" sortable={false} />
        <FunctionField render={({ last_paid_amount }) => last_paid_amount} label="Last paid amount" />
        <FunctionField
          render={({ last_payment_date }) => formatDatetime(last_payment_date)}
          label="Last payment date"
        />
        <FunctionField
          render={({ extension_activation_message }) => extension_activation_message || 'none'}
          label="Activation message"
        />
        <FunctionField
          render={({ extension_activation_status }) => extension_activation_status || 'none'}
          label="Activation status"
        />
      </Datagrid>
    </List>
  );
};

const LoanListExtensionRequest = ListFilterHOC(
  ListExtensionRequest,
  { is_extension_requested: 'true', enum_state: 'active' },
  [],
);

export default LoanListExtensionRequest;
