import React, { cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  BooleanField,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
  FunctionField,
  TextField,
} from 'react-admin';
import { grey } from '@material-ui/core/colors';
import { Button, makeStyles, Typography } from '@material-ui/core';
import TestScheduleDialog from './TestScheduleDialog';

const useStyles = makeStyles(() => ({
  noWrap: {
    whiteSpace: 'nowrap',
  },
  cellWidth: {
    width: '90px',
  },
}));

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const ProductList = props => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState(false);

  const classes = useStyles();

  const getRowStyle = record => {
    if (!record.product_is_actual) {
      return { backgroundColor: grey[100] };
    }
  };

  const handleOpenTestModal = (e, productCode) => {
    e.stopPropagation();
    setOpenDialog(true);
    setDialogTitle(productCode);
  };

  return (
    <>
      <List
        pagination={false}
        bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
        // filters={<ListFilter />}
        filterDefaultValues={{ is_actual: true }}
        actions={<ListActions />}
        {...props}>
        <Datagrid rowClick="show" rowStyle={getRowStyle}>
          <TextField source="id" />
          <FunctionField
            label="Test accrual schedule"
            render={record => (
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={e => handleOpenTestModal(e, record.code)}>
                <span className={classes.noWrap}>Test {record.code}</span>
              </Button>
            )}
          />
          <TextField source="code" />
          <TextField source="name" />
          <TextField source="strategy_id" />
          <BooleanField source="is_enabled" />
          <FunctionField
            label="Principal"
            render={record => (
              <div className={classes.cellWidth}>
                <Typography variant="body2">Min - {record.min_principal}</Typography>
                <Typography variant="body2">Max - {record.max_principal}</Typography>
              </div>
            )}
          />
          <FunctionField
            label="First loan principal"
            className={classes.cellWidth}
            render={record => (
              <div className={classes.cellWidth}>
                <Typography variant="body2">Min - {record.first_loan_min_principal}</Typography>
                <Typography variant="body2">Max - {record.first_loan_max_principal}</Typography>
              </div>
            )}
          />
          <TextField source="principal_step" />
          <FunctionField
            label="Tenor"
            className={classes.cellWidth}
            render={record => (
              <div className={classes.cellWidth}>
                <Typography variant="body2">Min - {record.min_tenor}</Typography>
                <Typography variant="body2">Max - {record.max_tenor}</Typography>
              </div>
            )}
          />
          <FunctionField
            label="First loan tenor"
            className={classes.cellWidth}
            render={record => (
              <div className={classes.cellWidth}>
                <Typography variant="body2">Min - {record.first_loan_min_tenor}</Typography>
                <Typography variant="body2">Max - {record.first_loan_max_tenor}</Typography>
              </div>
            )}
          />
          <TextField source="tenor_step" />
          <TextField source="first_loan_commission" />
          <TextField source="commission" />
          <TextField source="daily_interest_rate" />
          <TextField source="first_loan_daily_interest_rate" />
          <TextField source="late_payment_fee_add_max_term" />
          <TextField source="grace_period" />
          <TextField source="grace_sum" />
          <FunctionField
            label="Days to allow request extension"
            className={classes.cellWidth}
            render={record => (
              <div className={classes.cellWidth}>
                <Typography variant="body2">Min - {record.min_days_to_allow_request_extension}</Typography>
                <Typography variant="body2">Max - {record.max_days_to_allow_request_extension}</Typography>
              </div>
            )}
          />
          <TextField source="mark_loan_as_defaulted_after_days" />
          <TextField source="loan_agreement_template_code" />
          <TextField source="extension_loan_agreement_template_code" />
          <TextField source="dpd_threshold" />
          <TextField source="extension_dpd_threshold" />
        </Datagrid>
      </List>
      {openDialog && (
        <TestScheduleDialog openDialog={openDialog} title={dialogTitle} onClose={() => setOpenDialog(false)} />
      )}
    </>
  );
};

export default ProductList;
