import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Edit,
  TextInput,
  BooleanInput,
  SelectInput,
  SaveButton,
  DeleteButton,
  FormWithRedirect,
  required,
  maxLength,
  useDataProvider,
  useNotify,
  usePermissions,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import FilledInput from '@material-ui/core/FilledInput';
import TextField from '@material-ui/core/TextField';
import { Filter } from './Filter';
import {
  USER_EVENT as userEvents,
  TRANSMITTER as transmitters,
  formatChoices,
  formatFilter,
  USER_EVENT_NOTIFICATION_TYPES,
  USER_EVENT_NOTIFICATIONS_FILTERS_IDS,
} from '../../../utils/dictionary';
import Autocomplete from '../../autocomplete/Autocomplete';
import convertMinutesToDelay from '../../../utils/convertMinutesToDelay';
import { removeErrors } from '../../../utils/validator';

const useStyles = makeStyles(theme => ({
  chip: {
    fontSize: '14px',
    backgroundColor: '#C2C2C1',
  },
  formControl: {
    width: '100%',
    marginTop: '8px',
    marginBottom: '4px',
  },
  mt1: {
    marginTop: theme.spacing(1),
  },
}));

const FormWrapper = ({ filters, save, record, ...props }) => {
  const delay = convertMinutesToDelay(record?.delay);

  const [delayDays, setDelayDays] = useState(delay.delay_days || '');
  const [delayHours, setDelayHours] = useState(delay.delay_hours || '');
  const [delayMinutes, setDelayMinutes] = useState(delay.delay_minutes || '');
  const [errors, setErrors] = useState({});
  const [filterName, setFilterName] = useState([]);
  const [filterObject, setFilterObject] = useState({});
  const [trigger, setTrigger] = useState(record.trigger_event || '');
  const [selectedType, setSelectedType] = useState(record.type);
  const [sendFrom, setSendFrom] = useState('');
  const [sendTo, setSendTo] = useState('');
  const [templateFilter, setTemplateFilter] = useState(null);

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();
  const { permissions = [] } = usePermissions();
  const isEditable = permissions.includes('CAN_USER_EVENT_NOTIFICATION_EDIT');

  useEffect(() => {
    dataProvider
      .query('global_settings/by-name/marketing_events_send_from', {})
      .then(({ data }) => setSendFrom(data.value))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
    dataProvider
      .query('global_settings/by-name/marketing_events_send_to', {})
      .then(({ data }) => setSendTo(data.value))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  }, [dataProvider, notify]);

  useEffect(() => {
    if (!record.filters?.length) return;

    let filterObject = {};

    USER_EVENT_NOTIFICATIONS_FILTERS_IDS.forEach(({ id, index, ...rest }) => {
      const filter = record.filters?.find(({ id: recordId }) => id === recordId);

      filterObject = {
        ...filterObject,
        [id]: form => (
          <Filter
            form={form}
            key={index}
            id={id}
            filter={filter}
            choice={formatChoices(filters.find(i => i.id === id)?.description?.allowed_values || [])}
            {...rest}
          />
        ),
      };
    });

    const defaultFilters = filters.filter(i => record.filters?.find(j => j.id === i.id));

    setFilterObject(filterObject);
    setFilterName(defaultFilters);
  }, [filters, record.filters]);

  const handleChange = event => setFilterName(event.target.value);

  const handleChangeTrigger = e => {
    setTrigger(e.target.value);
    setFilterName(prev => prev.filter(i => i.supported_triggers.includes(e.target.value)));
  };

  const getTransformFilters = filters => {
    let filtersIds = {};
    USER_EVENT_NOTIFICATIONS_FILTERS_IDS.forEach(({ id, type }) => {
      filtersIds = { ...filtersIds, [id]: formatFilter(type, id, filters[id]) };
    });

    const newFilters = [];

    filterName.forEach(({ id }) => newFilters.push(filtersIds[id]));
    return newFilters;
  };

  const removeError = (...fields) => {
    setErrors(removeErrors(errors, ...fields));
  };

  const handleChangeDelayDays = e => {
    const delayDays = e.target.value;
    const formErrors = {};
    setDelayDays(delayDays || '');
    if (+delayDays < 0) {
      formErrors.delayDays = 'Must be at least 0';
      setErrors({ ...errors, ...formErrors });
    } else if (+delayDays > 119) {
      formErrors.delayDays = 'Must be 119 or less';
      setErrors({ ...errors, ...formErrors });
    } else {
      removeError('delayDays');
    }
  };

  const handleChangeDelayHours = e => {
    const delayHours = e.target.value;
    const formErrors = {};
    setDelayHours(e.target.value || '');
    if (+delayHours < 0) {
      formErrors.delayHours = 'Must be at least 0';
      setErrors({ ...errors, ...formErrors });
    } else if (+delayHours > 23) {
      formErrors.delayHours = 'Must be 23 or less';
      setErrors({ ...errors, ...formErrors });
    } else {
      removeError('delayHours');
    }
  };

  const handleChangeDelayMinutes = e => {
    const delayMinutes = e.target.value;
    const formErrors = {};
    setDelayMinutes(e.target.value || '');
    if (delayMinutes) {
      if (+delayMinutes < 0) {
        formErrors.delayMinutes = 'Must be at least 0';
        setErrors({ ...errors, ...formErrors });
      } else if (+delayMinutes > 59) {
        formErrors.delayMinutes = 'Must be 59 or less';
        setErrors({ ...errors, ...formErrors });
      } else {
        removeError('delayMinutes');
      }
    }
  };

  return (
    <FormWithRedirect
      save={(
        {
          name,
          is_enabled,
          trigger_event,
          interrupt_event,
          transmitter_id,
          alpha_name,
          type,
          calendar_template,
          template_id,
          affiliate,
          external_agency,
          ip_country,
          principal,
        },
        ...rest
      ) => {
        if (Object.keys(errors).length === 0) {
          const params = {
            name,
            is_enabled,
            trigger_event,
            interrupt_event,
            transmitter_id,
            alpha_name,
            type,
            calendar_template,
            template_id,
            filters: getTransformFilters({ affiliate, external_agency, ip_country, principal }),
          };
          const daysInMinutes = delayDays ? delayDays * 24 * 60 : 0;
          const hoursInMinutes = delayHours ? delayHours * 60 : 0;
          const delay = daysInMinutes + hoursInMinutes + +delayMinutes;

          save(...[{ ...params, delay: delay || 0, _params: { method: 'PATCH' } }, ...rest]);
        }
      }}
      validate={params => {
        const { affiliate, external_agency, ip_country, principal } = params;
        const filters = { affiliate, external_agency, ip_country, principal };
        const errors = {};
        Object.entries(filters).forEach(([key, value]) => {
          const type = USER_EVENT_NOTIFICATIONS_FILTERS_IDS.find(i => i.id === key)?.type;
          if (type === 'sign' && value?.values?.some(val => val.number === '')) {
            errors[key] = 'Value fields can not be empty';
          }
        });
        return errors;
      }}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={9}>
                      <Typography variant="h6" gutterBottom={false}>
                        Update user notification
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <BooleanInput label="Enabled" source="is_enabled" defaultValue={false} />
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextInput source="name" validate={[required(), maxLength(255)]} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        source="trigger_event"
                        choices={userEvents}
                        validate={[required()]}
                        fullWidth
                        disabled={!isEditable}
                        onChange={handleChangeTrigger}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        source="interrupt_event"
                        choices={userEvents}
                        allowEmpty
                        fullWidth
                        disabled={!isEditable}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        source="transmitter_id"
                        onChange={e => {
                          formProps.form.change('template_id', null);
                          formProps.form.resetFieldState('template_id');
                          if (e.target.value === 'cm') {
                            setTemplateFilter(null);
                          } else if (e.target.value === 'mailer') {
                            setTemplateFilter({ category: 'Email' });
                          } else {
                            setTemplateFilter({ category: 'SMS' });
                          }
                        }}
                        choices={transmitters.filter(t => t.id !== 'cm')}
                        validate={[required()]}
                        fullWidth
                        disabled={!isEditable}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        source="type"
                        choices={USER_EVENT_NOTIFICATION_TYPES}
                        fullWidth
                        validate={[required()]}
                        disabled={!isEditable}
                        onChange={e => setSelectedType(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box className={classes.mt1}>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                          {selectedType === 'marketing' &&
                            `Marketing notifications will be sent between ${sendFrom} and ${sendTo}. If there are several marketing events in the queue for sending, only the last one will be sent`}
                          {selectedType === 'service' &&
                            'Service notifications are not time-limited and will be sent with a set delay'}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="body2" mb="0" color="textSecondary" gutterBottom>
                            Delay should be no more than 120 days in total!
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            className={classes.formControl}
                            variant="filled"
                            type="number"
                            size="small"
                            label="Days"
                            value={delayDays || ''}
                            onChange={handleChangeDelayDays}
                            error={!!errors.delayDays}
                            helperText={errors.delayDays || 'Maximum 119 days'}
                            InputProps={{
                              inputProps: { min: 0, max: 119, step: 1 },
                            }}
                            onKeyPress={event => {
                              if (event?.key === '-' || event?.key === '+') {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            className={classes.formControl}
                            variant="filled"
                            type="number"
                            size="small"
                            label="Hours"
                            value={delayHours || ''}
                            onChange={handleChangeDelayHours}
                            error={!!errors.delayHours}
                            helperText={errors.delayHours || 'Maximum 23 hours'}
                            InputProps={{
                              inputProps: { min: 0, max: 23, step: 1 },
                            }}
                            onKeyPress={event => {
                              if (event?.key === '-' || event?.key === '+') {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            className={classes.formControl}
                            variant="filled"
                            type="number"
                            size="small"
                            label="Minutes"
                            value={delayMinutes || ''}
                            onChange={handleChangeDelayMinutes}
                            error={!!errors.delayMinutes}
                            helperText={errors.delayMinutes || 'Maximum 59 minutes'}
                            InputProps={{
                              inputProps: { min: 0, max: 59, step: 1 },
                            }}
                            onKeyPress={event => {
                              if (event?.key === '-' || event?.key === '+') {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        resource="templates"
                        source="calendar_template"
                        optionValueProp="key"
                        optionLabelProp="key"
                        label="Calendar attach template"
                        filter={{ category: 'Calendar' }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        resource="templates"
                        source="template_id"
                        optionValueProp="id"
                        optionLabelProp="key"
                        label="Template"
                        filter={templateFilter}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth variant="filled">
                        <InputLabel>Filters</InputLabel>
                        <Select
                          multiple
                          value={filterName}
                          onChange={handleChange}
                          input={<FilledInput label="Filters" />}
                          disabled={!trigger}
                          renderValue={selected => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                              {selected.map(({ id }) => (
                                <Chip
                                  className={classes.chip}
                                  key={id}
                                  label={filterObject[id](formProps.form).props.title}
                                />
                              ))}
                            </Box>
                          )}>
                          {filters.map(filter =>
                            filter.supported_triggers.includes(trigger) ? (
                              <MenuItem key={filter.id} value={filter}>
                                {filterObject?.[filter?.id]?.(formProps.form)?.props?.title}
                              </MenuItem>
                            ) : null,
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                {filterName.map(filter => (
                  <React.Fragment key={filter.id}>{filterObject[filter.id](formProps.form)}</React.Fragment>
                ))}
                <Box display="flex" justifyContent="space-between" padding={2}>
                  <SaveButton
                    disabled={!isEditable}
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                  <DeleteButton
                    undoable={false}
                    disabled={!isEditable}
                    basePath={formProps.basePath}
                    record={formProps.record}
                    resource={formProps.resource}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  record: PropTypes.shape({
    template_id: PropTypes.number,
    calendar_template: PropTypes.string,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        id: PropTypes.string,
        values: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            value: PropTypes.string,
            include: PropTypes.string,
          }),
        ),
      }),
    ),
    delay: PropTypes.number,
    trigger_event: PropTypes.string,
    type: PropTypes.string,
  }),
  filters: PropTypes.array,
};

const UserEventNotificationEdit = props => {
  const [filters, setFilters] = useState([]);
  const [error, setError] = useState(null);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .query('user_event_notifications/filters', { method: 'GET' })
      .then(({ data }) => setFilters(data))
      .catch(error => setError(error));

    return () => {
      setFilters([]);
    };
  }, [dataProvider]);

  if (error?.message) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <Edit component="div" mutationMode="pessimistic" {...props}>
      <FormWrapper filters={filters} />
    </Edit>
  );
};

export default UserEventNotificationEdit;
