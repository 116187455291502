import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ChipField,
  FunctionField,
  useDataProvider,
  useNotify,
  usePermissions,
} from 'react-admin';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import ListFilterHOC from '../../hoc/ListFilterHOC';
import ListActions from './components/ListActions';
import ListFilters from './components/ListFilters';
import Pagination from '../../Pagination';
import Call from '../../call/Call';
import { formatDatetime, formatPhoneNumber } from '../../../utils/formatter';
import { CURRENCY } from '../../../constants';

const ListSold = ({ statefilterchoices = [], listfilters: ListFilters, ...props }) => {
  const [agenciesList, setAgenciesList] = React.useState([]);

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { permissions = [] } = usePermissions();

  const canSeePhone = !permissions.includes('CAN_SEE_FULL_PHONE');

  useEffect(() => {
    dataProvider
      .query('external_agencies?page=1&items_per_page=200', { method: 'GET' })
      .then(({ data }) => setAgenciesList(data))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  }, [dataProvider, notify]);

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilters statefilterchoices={statefilterchoices} />}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <FunctionField
          label="User phone"
          render={({ user_id, user_phone_number }) => (
            <Call userId={user_id} userPhone={user_phone_number}>
              {formatPhoneNumber(user_phone_number, canSeePhone)}
            </Call>
          )}
        />
        <FunctionField
          label="User name"
          render={({ user_id, user_first_name = '', user_last_name = '' }) => (
            <Link onClick={e => e.stopPropagation()} href={'#users/' + user_id}>
              {`${user_first_name} ${user_last_name} #${user_id}`}
            </Link>
          )}
        />
        <TextField source="product_name" label="Product name" sortable={false} />
        <NumberField source="principal" options={{ style: 'currency', currency: CURRENCY }} />
        <NumberField source="tenor" />
        <ChipField size="small" source="state" sortable={false} />
        <FunctionField
          label="Loan type"
          source="is_repeat"
          sortable={false}
          render={(record, key) => <Chip size="small" label={record[key] ? 'Repeated' : 'New'} />}
        />
        <FunctionField label="Created at" source="created_at" render={({ created_at }) => formatDatetime(created_at)} />
        <FunctionField label="Sold at" source="sold_at" render={({ sold_at }) => formatDatetime(sold_at)} />
        <FunctionField
          label="External agency name"
          sortable={false}
          render={({ external_agency_id }) => agenciesList.find(i => i.id === external_agency_id)?.name}
        />
        <FunctionField
          label="External agency phone"
          sortable={false}
          render={({ external_agency_id }) => {
            const agency = agenciesList.find(i => i.id === external_agency_id);
            return agency ? formatPhoneNumber(agency.phone) : null;
          }}
        />
        <FunctionField
          label="External agency email"
          sortable={false}
          render={({ external_agency_id }) => agenciesList.find(i => i.id === external_agency_id)?.email}
        />
        <FunctionField
          render={record => (
            <Link href={`#loans/${record.id}/show`} underline="none" target="_blank" rel="noreferrer">
              <IconButton onClick={e => e.stopPropagation()}>
                <OpenInNewIcon fontSize="small" />
              </IconButton>
            </Link>
          )}
        />
      </Datagrid>
    </List>
  );
};

ListSold.propTypes = {
  statefilterchoices: PropTypes.array,
  permissions: PropTypes.arrayOf(PropTypes.string),
  listfilters: PropTypes.func,
};

const LoanListSold = ListFilterHOC(ListSold, { state: 'sold' }, [], ListFilters);

export default LoanListSold;
