import React from 'react';
import { TextField, NumberField, ChipField, FunctionField } from 'react-admin';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';

import { formatDatetime } from '../../../utils/formatter';
import { CURRENCY } from '../../../constants';

export const LOANS_LIST_PENDING = [
  <TextField key="id" source="id" />,
  <FunctionField
    key="name"
    label="Name"
    render={({ user_id, user_first_name = '', user_last_name = '' }) => (
      <Link onClick={e => e.stopPropagation()} href={'#users/' + user_id}>
        {`${user_first_name} ${user_last_name} #${user_id}`}
      </Link>
    )}
  />,
  <TextField key="user_dni" source="user_dni" label="DNI" sortable={false} />,
  <NumberField key="principal" source="principal" options={{ style: 'currency', currency: CURRENCY }} />,
  <NumberField key="tenor" source="tenor" />,
  <ChipField key="state" size="small" source="state" sortable={false} />,
  <FunctionField
    key="is_repeat"
    sortable={false}
    label="Loan type"
    source="is_repeat"
    render={(record, key) => <Chip size="small" label={record[key] ? 'Repeated' : 'New'} />}
  />,
  <FunctionField
    key="created_at"
    source="created_at"
    label="Created at"
    render={({ created_at }) => formatDatetime(created_at)}
  />,
  <FunctionField
    key="disbursed_at"
    source="disbursed_at"
    label="Disbursed at"
    render={({ disbursed_at }) => formatDatetime(disbursed_at)}
  />,
  <FunctionField
    key="matured_at"
    source="matured_at"
    label="Matured at"
    render={({ matured_at }) => formatDatetime(matured_at)}
  />,
  <TextField
    key="transaction_status_code"
    source="last_money_transfer_result_status_code"
    label="Transaction status code"
    sortable={false}
  />,
  <TextField key="account_number" source="account_number" label={'Account number'} sortable={false} />,
  <FunctionField
    key="button_tab"
    render={record => (
      <Link href={`#loans/${record.id}/show`} underline="none" target="_blank" rel="noreferrer">
        <IconButton onClick={e => e.stopPropagation()}>
          <OpenInNewIcon fontSize="small" />
        </IconButton>
      </Link>
    )}
  />,
];

export const LOAN_LIST_ROWS = [
  <TextField key="id" source="id" />,
  <FunctionField
    key="name"
    label="Name"
    render={({ user_id, user_first_name = '', user_last_name = '' }) => (
      <Link onClick={e => e.stopPropagation()} href={'#users/' + user_id}>
        {`${user_first_name} ${user_last_name} #${user_id}`}
      </Link>
    )}
  />,
  <TextField key="user_dni" label="DNI" source="user_dni" sortable={false} />,
  <NumberField key="principal" source="principal" options={{ style: 'currency', currency: CURRENCY }} />,
  <NumberField key="tenor" source="tenor" />,
  <ChipField key="state" size="small" source="state" sortable={false} />,
  <FunctionField
    key="is_repeat"
    label="Loan type"
    source="is_repeat"
    sortable={false}
    render={(record, key) => <Chip size="small" label={record[key] ? 'Repeated' : 'New'} />}
  />,
  <FunctionField
    key="created_at"
    label="Created at"
    source="created_at"
    render={({ created_at }) => formatDatetime(created_at)}
  />,
  <FunctionField
    key="disbursed_at"
    label="Disbursed at"
    source="disbursed_at"
    render={({ disbursed_at }) => formatDatetime(disbursed_at)}
  />,
  <FunctionField
    key="matured_at"
    label="Matured at"
    source="matured_at"
    render={({ matured_at }) => formatDatetime(matured_at)}
  />,
  <TextField key="account_number" source="account_number" label={'Account number'} sortable={false} />,
  <FunctionField
    key="button_tab"
    render={record => (
      <Link href={`#loans/${record.id}/show`} underline="none" target="_blank" rel="noreferrer">
        <IconButton onClick={e => e.stopPropagation()}>
          <OpenInNewIcon fontSize="small" />
        </IconButton>
      </Link>
    )}
  />,
];
