import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  TableFooter,
  TextField,
  Typography,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { removeErrors } from '../../../utils/validator';
import { useDataProvider, useNotify } from 'react-admin';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { formatCurrency, formatDatetime, formatMoneyNoCurrency } from '../../../utils/formatter';
import TransactionType from '../../field/TransactionType';
import getAdminTimezone from '../../../utils/getAdminTimezone';
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { CURRENCY } from '../../../constants';

const initialScheduleSettings = {
  product_code: '',
  is_client_new: false,
  disbursed_at: null,
  tenor: 0,
  principal: 0,
  not_paid_principal: 0,
};

const useStyles = makeStyles(() => ({
  tableWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    overflow: 'auto',
  },
  table: { position: 'relative', borderCollapse: 'separate' },
  dialogTitle: {
    marginBottom: '16px',
  },
  bold: {
    fontWeight: 600,
  },
  leftContent: {
    width: '40%',
    flexGrow: 1,
    paddingRight: '20px',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  },
  stickyFooter: {
    position: 'sticky',
    bottom: '-1px',
    backgroundColor: '#fff',
  },
  stickyCell: {
    borderTop: '1px solid #e0e0e0',
  },
}));

const validateField = (value, fieldName) => {
  if (value === undefined || value === null) {
    return `${fieldName} can't be empty`;
  }

  if (value < 0) {
    return `${fieldName} can't be negative`;
  }

  return null;
};

const TestScheduleDialog = ({ openDialog, title, onClose }) => {
  const [errors, setErrors] = useState({});
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [responseCalcData, setResponseCalcData] = useState(null);

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();

  useEffect(() => {
    setState(prevState => ({ ...prevState, product_code: title }));
  }, [title]);

  const removeError = (...fields) => {
    setErrors(removeErrors(errors, ...fields));
  };

  const validate = () => {
    const { disbursed_at, tenor, principal, not_paid_principal } = state;
    const formErrors = {};

    if (!disbursed_at) {
      formErrors.disbursed_at = 'Disbursement date is required';
    }

    const tenorError = validateField(tenor, 'Tenor');
    if (tenorError) {
      formErrors.tenor = tenorError;
    }

    const principalError = validateField(principal, 'Principal');
    if (principalError) {
      formErrors.principal = principalError;
    }

    const notPaidPrincipalError = validateField(not_paid_principal, 'Not paid principal');
    if (notPaidPrincipalError) {
      formErrors.not_paid_principal = notPaidPrincipalError;
    }

    const isValid = Object.keys(formErrors).length === 0;

    setErrors(formErrors);
    setLoading(false);

    return isValid;
  };

  const handleChangeValue = (fieldName, value) => {
    removeError(fieldName);
    setState(prevState => ({ ...prevState, [fieldName]: value }));
  };

  const handleClose = () => {
    setState(initialScheduleSettings);
    setErrors({});
    onClose();
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (validate()) {
      setLoading(true);
      const dateTimeTimezones = formatInTimeZone(state.disbursed_at, getAdminTimezone(), "yyyy-MM-dd'T'HH:mm:ssXXX");
      const payload = {
        ...state,
        disbursed_at: dateTimeTimezones,
        tenor: +state.tenor,
        principal: +state.principal,
        not_paid_principal: +state.principal,
      };

      dataProvider
        .post('products-get-schedule', { data: payload })
        .then(({ data }) => {
          setResponseCalcData(data.data);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          notify(`Error: ${error.message}`, 'error');
        });
    }
  };

  const sumAmount = useMemo(() => {
    if (responseCalcData) {
      const sum = responseCalcData.transactions?.reduce((acc, curr) => acc + curr.amount, 0);
      return formatCurrency(sum);
    }
  }, [responseCalcData]);

  const displayDate = state.disbursed_at ? utcToZonedTime(state.disbursed_at, getAdminTimezone()) : null;

  return (
    <Dialog open={openDialog} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="lg">
      <DialogTitle id="form-dialog-title">{`Calculate accrual schedule for ${state.product_code}`}</DialogTitle>
      <DialogContent style={{ display: 'flex', gap: '20px', paddingRight: 0, overflow: 'hidden' }}>
        <form className={classes.leftContent}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    value={state.is_client_new}
                    onChange={e => handleChangeValue('is_client_new', e.target.checked)}
                  />
                }
                label="Is client new?"
              />
            </Grid>
            <Grid item xs={12}>
              <DateTimePicker
                fullWidth
                label="Disbursement date"
                value={displayDate}
                error={!!errors.disbursed_at}
                helperText={errors.disbursed_at}
                onChange={value => handleChangeValue('disbursed_at', zonedTimeToUtc(value, getAdminTimezone()))}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Tenor"
                value={state.tenor ?? 0}
                type="number"
                error={!!errors.tenor}
                helperText={errors.tenor}
                onChange={event => handleChangeValue('tenor', event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Principal"
                type="number"
                error={!!errors.principal}
                helperText={errors.principal}
                value={state.principal ?? 0}
                onChange={event => handleChangeValue('principal', event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Not paid principle"
                type="number"
                error={!!errors.not_paid_principal}
                helperText={errors.not_paid_principal}
                value={state.not_paid_principal ?? 0}
                onChange={event => handleChangeValue('not_paid_principal', event.target.value)}
              />
            </Grid>
          </Grid>
          <Button variant="contained" onClick={handleSubmit} color="primary" style={{ marginTop: '16px' }}>
            {loading ? 'Calculating...' : 'Calculate'}
          </Button>
        </form>
        <div className={classes.tableWrapper}>
          {loading ? (
            <CircularProgress size={50} />
          ) : responseCalcData ? (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 16, marginBottom: '24px', flexWrap: 'wrap' }}>
                  <Typography variant="subtitle2">
                    Product - <span className={classes.bold}>{responseCalcData.product_code}</span>
                  </Typography>
                  <Typography variant="subtitle2">
                    Disbursement date -{' '}
                    <span className={classes.bold}>{formatDatetime(responseCalcData.disbursed_at)}</span>
                  </Typography>
                  <Typography variant="subtitle2">
                    Tenor -{' '}
                    <span className={classes.bold}>
                      {responseCalcData.tenor} {responseCalcData.tenor > 1 ? 'days' : 'day'}
                    </span>
                  </Typography>
                  <Typography variant="subtitle2">
                    Principal - {CURRENCY}{' '}
                    <span className={classes.bold}>{formatMoneyNoCurrency(responseCalcData.principal)}</span>
                  </Typography>
                  <Typography variant="subtitle2">
                    Maturity date -{' '}
                    <span className={classes.bold}>{formatDatetime(responseCalcData.maturity_date)}</span>
                  </Typography>
                  <Typography variant="subtitle2">
                    Maturity date with holidays -{' '}
                    <span className={classes.bold}>{formatDatetime(responseCalcData.maturity_date_with_holidays)}</span>
                  </Typography>
                </Box>
                <Typography variant="h6" gutterBottom={true} className={classes.dialogTitle}>
                  Scheduled transactions
                </Typography>
                <Divider />
                <Table className={classes.table} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ maxWidth: '30px' }}>Day number</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell align="right">Amount, {CURRENCY}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {responseCalcData.transactions
                      .sort((itemA, itemB) => itemA.day_number - itemB.day_number)
                      .map((row, id) => (
                        <TableRow key={id}>
                          <TableCell>{row.day_number}</TableCell>
                          <TableCell>{formatDatetime(row.scheduled_at)}</TableCell>
                          <TableCell>
                            <TransactionType type={row.type} />
                          </TableCell>
                          <TableCell align="right">{formatMoneyNoCurrency(row.amount)}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  <TableFooter className={classes.stickyFooter}>
                    <TableRow>
                      <TableCell style={{ maxWidth: '30px' }} className={classes.stickyCell}></TableCell>
                      <TableCell className={classes.stickyCell}></TableCell>
                      <TableCell className={classes.stickyCell}></TableCell>
                      <TableCell align="right" className={classes.stickyCell}>
                        Total: {sumAmount}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </Box>
            </>
          ) : (
            <Typography variant="subtitle1">Fill in values and calculate</Typography>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TestScheduleDialog.propTypes = {
  openDialog: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
  errors: PropTypes.object,
  onClose: PropTypes.func,
  onCalcSubmit: PropTypes.func,
  onChangeValue: PropTypes.func,
};

export default TestScheduleDialog;
